import platformConstants from '@/constants/platform';
export default {
  CLASS_STATUS: { // 上課狀況
    NEW_UPGRADE: 'newUpgrade', // 剛升等
    FIRST_CLASS: 'firstClass', // 第一堂課
    NEW_STUDENT: 'newStudent', // 新學員
    ON_THE_GO: 'onTheGo', // 活躍學員
  },
  CLASS_STATUS_ABBREVIATION: { // 上課狀況縮寫
    NEW_UPGRADE: 'NU',
    FIRST_CLASS: 'FC',
    NEW_STUDENT: '7',
    ON_THE_GO: 'OTG',
  },
  PLATFORM_LEVEL: {
    [platformConstants.WUWOW]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    [platformConstants.WUWOW_JUNIOR]: [0, 1, 2, 3, 4, 5, 6],
  },
};
