<template lang="pug">
vue-good-table(
  @on-selected-rows-change="onSelectedRowsChange"
  @on-page-change="onPageChange"
  @on-per-page-change="onPerPageChange"
  :columns="columns"
  :rows="rows"
  :theme="theme"
  :totalRows="totalRows"
  :pagination-options="options"
  :select-options="selectOptions"
)
  template(slot="selected-row-actions")
    slot(name="selected-row-actions")
  template(slot="table-row" slot-scope="props")
    slot(name="table-row" :data="props")
</template>

<script>
// vue-good-table 解構為大駝峰因此這邊使用大駝峰引入套件
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
export default {
  name: 'VipRequestsClassroomFilter',

  components: {
    // vue-good-table 解構為大駝峰因此這邊使用大駝峰引入套件
    VueGoodTable,
  },

  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      default() {
        return 'black-rhino';
      },
    },
    selectOptions: {
      type: Object,
      default() {
        return {
          enabled: true,
          selectOnCheckboxOnly: true,
        };
      },
    },
  },

  data() {
    return {
      options: {
        enabled: true,
        mode: 'pages',
        perPage: 10,
        perPageDropdown: [5, 10, 15, 20],
        jumpFirstOrLast: true,
      },
      serverParams: {
        page: 1,
        perPage: 10,
        sort: null,
      },
    };
  },

  computed: {
    rows() {
      if (!this.data) {
        return [];
      }
      return this.data.data;
    },
    totalRows() {
      if (!this.data.meta) {
        return 0;
      }
      return this.data.meta.pagination.total;
    },
  },

  methods: {
    onSelectedRowsChange({ selectedRows }) {
      this.$emit('onSelectedRowsChange', selectedRows);
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.loadItems();
    },
    loadItems() {
      const serverParams = Object.assign({}, {
        page: this.serverParams.page,
        perPage: this.serverParams.perPage,
        sort: this.serverParams.sort,
      });
      this.$emit('load-data', serverParams);
    },
  },
};
</script>
