<template lang="pug">
.wuwow-card
  .wuwow-card-head
    //- Title
    h4.wuwow-card-title {{ $t('vipRequestsClassroom') }}
  .wuwow-card-body
    //- FILTER
    vip-requests-filter
    hr
    //- Note
    //- shorthand-description.mb-2
    //- TABLE
    vip-requests-table
</template>

<script>
// import shorthandDescription from '@/components/consultant/cloudClassroom/shorthandDescription';
import vipRequestsTable from '@/components/consultant/vipRequests/table/index.vue';
import vipRequestsFilter from '@/components/consultant/vipRequests/filter/index.vue';

// i18n 需要請顧問翻譯
import i18n from '@/views/console/classroom/vipRequests/lang';

export default {
  name: 'VipRequestsClassroom',

  components: {
    vipRequestsFilter,
    // shorthandDescription,
    vipRequestsTable,
  },

  i18n,
};
</script>
