export default {
  messages: {
    tw: {
      clear: '清空',
      vipRequestsClassroom: '學員請求課程',
      agreeList: '同意列表',
      disagreeList: '不同意列表',
      agreeListConfirmQuestion: '確定<span class="text-success">同意</span>學員請求課程?',
      disagreeListConfirmQuestion: '確定<span class="text-danger">取消</span>學員請求課程?',
      datetimeRange: '日期與時間範圍',
      vipName: '學員名字',
      status: '狀態',
      proposal: '學員向顧問提出預約時間',
      have_read: '已讀',
      conversing: '交談中',
      consultant_reject: '顧問拒絕本次預約時間',
      consultant_approve: '顧問同意',
      student_cancel: '學生取消提出預約時間',
      schedule_reject: '顧問未回覆系統自動拒絕',
      requestDeleteTime: '學員請求自動刪除時間',
      materialFile: '教材檔案',
    },
    en: {
      clear: 'Clear',
      vipRequestsClassroom: 'Students Request Courses',
      agreeList: 'Agree List',
      disagreeList: 'Disagree List',
      agreeListConfirmQuestion: 'Confirm to <span class="text-success">"Accept"</span> requested lessons?',
      disagreeListConfirmQuestion: 'Confirm the <span class="text-danger">"Cancellation"</span> of requested lessons?',
      datetimeRange: 'Date and Time range',
      vipName: 'Vip Name',
      status: 'Status',
      proposal: 'Proposal',
      have_read: 'Have Read',
      conversing: 'Conversing',
      consultant_reject: 'Consultant Reject',
      consultant_approve: 'Consultant Approve',
      student_cancel: 'Student Cancel',
      schedule_reject: 'Schedule Reject',
      requestDeleteTime: 'Request Delete Time',
      materialFile: 'Material File',
    },
  },
};
