<template lang="pug">
b-modal#confirmModal(
  :title="operationStatus ? $t('agreeList') : $t('disagreeList')"
  header-bg-variant="primary"
  :ok-title="$t('confirm')"
  :cancle-title="$t('cancle')"
  centered
  button-size="sm"
  @ok="modalClickOk()"
)
  div.text-center
    h5.mb-4(v-html="operationStatus ? $t('agreeListConfirmQuestion') : $t('disagreeListConfirmQuestion')")
    div.confirm-modal-content(:class="{'text-left': selectedRow.length > 1}")
      p(v-for="row in selectedRow" :key="row.id")
        font-awesome-icon(:icon="['far', 'calendar']")
        span ：{{row.class_time}}
        font-awesome-icon.ml-2(icon="user")
        span ：{{row.vip.english_name}}
  hr
</template>

<script>
import i18n from '@/views/console/classroom/vipRequests/lang';
import lodash from 'lodash';

export default {
  name: 'ConfirmModal',

  props: {
    selectedRow: {
      type: Array,
      default() {
        return [];
      },
    },
    operationStatus: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  i18n,

  methods: {
    modalClickOk() {
      const selectData = {
        askConsultantTimeslotIds: lodash.map(this.selectedRow, 'id'),
        operationStatus: this.operationStatus,
      };
      this.$emit('modalClickOk', selectData);
    },
  },
};
</script>
<style scoped>
.confirm-modal-content{
  width: 300px;
  margin: 0 auto;
}
</style>
