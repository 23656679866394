<template lang="pug">
div
  //- 訂完課出現的警告提示 先空著待串接
  //- b-alert(show variant="success") Success Alert
  mutiple-select-table(
    @onSelectedRowsChange="mutipleSelectDatas"
    :columns="columns"
    :data="studentRequestsList"
    :selectOptions="mutipleSelectTableselectOptions"
    @load-data="loadData"
  )
    //- 批次勾選時最上面會出現允許或拒絕的按鈕
    div(slot="selected-row-actions")
      b-button.mr-2(variant="success" size="sm" v-b-modal.confirmModal @click="selectAgreeItem({})")
        font-awesome-icon(:icon="['far', 'calendar-check']" size="2x")
      b-button(variant="danger" size="sm" v-b-modal.confirmModal @click="selectDiagreeItem({})")
        font-awesome-icon(:icon="['far', 'calendar-xmark']" size="2x")
    template(slot="table-row" slot-scope="props")
      span(v-if="props.data.column.field === slotFields.dateTime")
        span {{ props.data.row.class_time }}
      span(v-if="props.data.column.field === slotFields.vipName")
        span {{ props.data.row.vip.chinese_name }}
      span(v-if="props.data.column.field === slotFields.level")
        h5
          b-badge(variant="primary") {{ props.data.row.level }}
      span(v-if="props.data.column.field === slotFields.status")
        span {{$t(props.data.row.status)}}
      //- span(v-if="props.data.column.field === slotFields.note")
      //-   span(v-for="(status, index) in statusList" :key="index" )
      //-     span(v-if="props.data.row.note[status]")
      //-       b-badge.mr-1(:variant="getStatusVariant(status)") {{ statusAbbreviation(status) }}
      span(v-if="props.data.column.field === slotFields.operate")
        div(v-if="canShowButton(props.data.row.status)")
          //- 同意按鈕
          b-button.mr-2(variant="success" size="sm" v-b-modal.confirmModal @click="selectAgreeItem(props.data.row)" :disabled="mutipleSelect")
            font-awesome-icon(:icon="['far', 'calendar-check']" size="2x")
          //- 拒絕按鈕
          b-button(variant="danger" size="sm" v-b-modal.confirmModal @click="selectDiagreeItem(props.data.row)" :disabled="mutipleSelect")
            font-awesome-icon(:icon="['far', 'calendar-xmark']" size="2x")
        div(v-else)
          span {{$t(props.data.row.status)}}
      span(v-if="props.data.column.field === slotFields.requestDeleteTime")
        span {{$t(props.data.row.auto_cancel_time)}}
      span(v-if="props.data.column.field === slotFields.materialFile")
        b-button(v-if="props.data.row.attachment_url" size="sm" variant="dark" @click="downMaterialFile(props.data.row.attachment_url)")
          font-awesome-icon(:icon="['fas', 'download']")
  //- Modal
  confirm-modal(:operationStatus="operationStatus" :selectedRow="selectedRow" @modalClickOk="modalClickOk")
</template>

<script>
import mutipleSelectTable from '@/components/table/mutipleSelectTable/index.vue';
import confirmModal from '@/components/consultant/vipRequests/confirmModal/index.vue';
import i18n from '@/views/console/classroom/vipRequests/lang';
import vipConstants from '@/constants/vip';
import htmlElementConstants from '@/constants/htmlElement';
import LioshutanApi from '@/api';
import { mapState } from 'vuex';
import lodash from 'lodash';

const tableColumnsFields = { dateTime: 'dateTime', vipName: 'vipName', level: 'level', operate: 'operate', status: 'status', requestDeleteTime: 'requestDeleteTime', materialFile: 'materialFile' };

export default {
  name: 'VipRequestsClassroomFilter',

  components: {
    mutipleSelectTable,
    confirmModal,
  },

  data() {
    return {
      mutipleSelectTableselectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        clearSelectionText: this.$t('clear'),
      },
      slotFields: {
        dateTime: tableColumnsFields.dateTime,
        vipName: tableColumnsFields.vipName,
        level: tableColumnsFields.level,
        operate: tableColumnsFields.operate,
        status: tableColumnsFields.status,
        requestDeleteTime: tableColumnsFields.requestDeleteTime,
        materialFile: tableColumnsFields.materialFile,
      },
      columns: [
        {
          label: `${this.$t('class.class')} ${this.$t('time')}`,
          field: tableColumnsFields.dateTime,
          tdClass: 'text-center', // 欄的內容置中
          thClass: 'text-center', // 標題的內容置中
        },
        {
          label: `Vip ${this.$t('name')}`,
          field: tableColumnsFields.vipName,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: this.$t('level'),
          field: tableColumnsFields.level,
          tdClass: 'text-center',
          thClass: 'text-center',
          type: 'number',
        },
        {
          label: this.$t('operate'),
          field: tableColumnsFields.operate,
          tdClass: 'text-center',
          thClass: 'text-center',
          html: true,
        },
        {
          label: this.$t('status'),
          field: tableColumnsFields.status,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: this.$t('requestDeleteTime'),
          field: tableColumnsFields.requestDeleteTime,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: this.$t('materialFile'),
          field: tableColumnsFields.materialFile,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      // TODO 假資料，後續串接API By Asa
      rows: [
        {
          id: 1,
          date: '2022-05-06',
          name: 'John',
          level: 1,
          note: { newUpgrade: true, firstClass: false, newStudent: true, onTheGo: false },
          operation: '<button>Agree</button> <button>Disagree</button>',
        },
        {
          id: 2,
          date: '2022-05-07',
          name: 'Jane',
          level: 3,
          note: { newUpgrade: true, firstClass: false, newStudent: true, onTheGo: false },
          operation: '<button>Agree</button> <button>Disagree</button>',
        },
        {
          id: 3,
          date: '2022-05-08',
          name: 'Susan',
          level: 7,
          note: { newUpgrade: false, firstClass: false, newStudent: true, onTheGo: false },
          operation: '<button>Agree</button> <button>Disagree</button>',
        },
        {
          id: 4,
          date: '2022-05-09',
          name: 'Chris',
          level: 6,
          note: { newUpgrade: true, firstClass: true, newStudent: false, onTheGo: true },
          operation: '<button>Agree</button> <button>Disagree</button>',
        },
        {
          id: 5,
          date: '2022-05-10',
          name: 'Dan',
          level: 5,
          note: { newUpgrade: false, firstClass: false, newStudent: true, onTheGo: true },
          operation: '<button>Agree</button> <button>Disagree</button>',
        },
        {
          id: 6,
          date: '2022-05-10',
          name: 'John',
          level: 9,
          note: { newUpgrade: false, firstClass: true, newStudent: true, onTheGo: false },
          operation: '<button>Agree</button> <button>Disagree</button>',
        },
      ],
      // statusList: [
      //   vipConstants.CLASS_STATUS.NEW_UPGRADE,
      //   vipConstants.CLASS_STATUS.FIRST_CLASS,
      //   vipConstants.CLASS_STATUS.NEW_STUDENT,
      //   vipConstants.CLASS_STATUS.ON_THE_GO,
      // ],
      selectedRow: [],
      operationStatus: false,
      mutipleSelect: false,
      studentRequestsList: {},
    };
  },

  created(){
    this.getStudentRequestsList();
  },

  i18n,

  computed: {
    ...mapState({
      vipRequestsSearchParams: state => state.consultant.vipRequestsSearchParams,
      searchVipRequests: state => state.consultant.vipRequestsSearch,
    }),
  },

  watch: {
    searchVipRequests(val){
      if (val) {
        this.getStudentRequestsList(this.vipRequestsSearchParams);
      }
    },
  },

  methods: {
    async getStudentRequestsList(query) {
      try {
        const response = await LioshutanApi.consultant.queryStudentRequestsList(query);
        this.studentRequestsList = response.data;
        this.$store.dispatch('consultant/setVipRequestsSearch', false);
      } catch (error) {
        console.log(error);
      }
    },
    mutipleSelectDatas(selectedRows) {
      this.mutipleSelect = selectedRows.length !== 0;
      this.selectedRow = selectedRows;
    },
    singleSelectData(data) {
      this.selectedRow = [];
      this.selectedRow.push(data);
    },
    selectAgreeItem(data) {
      this.operationStatus = true;
      if (!this.mutipleSelect) {
        this.singleSelectData(data);
      }
    },
    selectDiagreeItem(data) {
      this.operationStatus = false;
      if (!this.mutipleSelect) {
        this.singleSelectData(data);
      }
    },
    statusAbbreviation(value) {
      switch (value) {
        case vipConstants.CLASS_STATUS.NEW_UPGRADE:
          return vipConstants.CLASS_STATUS_ABBREVIATION.NEW_UPGRADE;
        case vipConstants.CLASS_STATUS.FIRST_CLASS:
          return vipConstants.CLASS_STATUS_ABBREVIATION.FIRST_CLASS;
        case vipConstants.CLASS_STATUS.NEW_STUDENT:
          return vipConstants.CLASS_STATUS_ABBREVIATION.NEW_STUDENT;
        case vipConstants.CLASS_STATUS.ON_THE_GO:
          return vipConstants.CLASS_STATUS_ABBREVIATION.ON_THE_GO;
        default:
          return '';
      }
    },
    getStatusVariant(value) {
      switch (value) {
        case vipConstants.CLASS_STATUS.NEW_UPGRADE:
          return htmlElementConstants.COLOR.LIGHT_BLUE;
        case vipConstants.CLASS_STATUS.FIRST_CLASS:
          return htmlElementConstants.COLOR.RED;
        case vipConstants.CLASS_STATUS.NEW_STUDENT:
          return htmlElementConstants.COLOR.YELLOW;
        case vipConstants.CLASS_STATUS.ON_THE_GO:
          return htmlElementConstants.COLOR.BLACK;
        default:
          return '';
      }
    },
    async modalClickOk(selectData) {
      try {
        if (selectData.operationStatus) {
          // 同意Modal
          const response = await LioshutanApi.consultant.approveAskConsultant(selectData.askConsultantTimeslotIds);
          console.log(response);
        } else {
          // 拒絕Modal
          const response = await LioshutanApi.consultant.rejectAskConsultant(selectData.askConsultantTimeslotIds);
          console.log(response);
        }
        this.$store.dispatch('consultant/setVipRequestsSearch', true);
      } catch (error) {
        console.log(error);
      }
    },
    loadData(data) {
      const params = {
        ...data,
        ...this.vipRequestsSearchParams,
      };
      this.getStudentRequestsList(params);
    },
    canShowButton(status) {
      // 以下狀態可以被同意或拒絕
      const canShow = ['proposal', 'have_read', 'conversing'];
      return lodash.includes(canShow, status);
    },
    downMaterialFile(url){
      window.open(url);
    },
  },
};
</script>
