<template lang="pug">
div
  .row
    .col-md-3
      label {{ $t('datetimeRange') }}
      select-date-and-time(:datetime.sync="filterParams.classTimeRange" :minuteStep="30")
    .col-md-3
      label {{ $t('vipName') }}
      b-form-input(v-model="filterParams.studentName" :placeholder="`${$t('please')} ${$t('input')} ${$t('vipName')}`")
    .col-md-3
      label {{ $t('status') }}
      b-form-select(v-model="filterParams.status" :options="setStatusOptions()")
    .col-md-3
      br
      b-button.mr-2(variant="primary" @click="search()") {{ $t('go') }}
      b-button(variant="outline-primary" @click="reset()") {{ $t('reset') }}
</template>

<script>
import selectDateAndTime from '@/components/filter/selectDateAndTime';
import i18n from '@/views/console/classroom/vipRequests/lang';
import { map } from 'lodash';
export default {
  name: 'VipRequestsClassroomFilter',

  components: {
    selectDateAndTime,
  },

  i18n,

  data() {
    return {
      filterParams: {
        classTimeRange: [],
        studentName: '',
        status: null,
      },
      // TODO: 假資料
      statusOptions: ['proposal', 'have_read', 'conversing', 'consultant_reject', 'consultant_approve', 'student_cancel', 'schedule_reject'],
    };
  },

  created() {
  },

  methods: {
    search(){
      const params = this.filterParams;
      if (params.studentName === '') {
        delete params.studentName;
      }
      if (!params.status) {
        delete params.status;
      }
      this.$store.dispatch('consultant/setVipRequestsSearchParams', this.filterParams); // 將搜尋的參數存到vuex
      this.$store.dispatch('consultant/setVipRequestsSearch', true); // True的話會重新打api更新表格
    },
    reset(){
      this.filterParams = {
        classTimeRange: [],
        studentName: '',
        status: null,
      };
      this.$store.dispatch('consultant/setVipRequestsSearchParams', {});
      this.$store.dispatch('consultant/setVipRequestsSearch', true);
    },
    setStatusOptions(){
      // 將後端給的陣列參數加上i18n
      const option = [{
        value: null,
        text: `${this.$t('please')} ${this.$t('input')} ${this.$t('status')}`,
        disabled: true,
      }];
      map(this.statusOptions, (item) => {
        const itemOption = {
          value: item,
          text: this.$t(item),
        };
        option.push(itemOption);
      });
      return option;
    },
  },
};
</script>
<style lang="scss" scoped>
// .btn {
//   width: 40%;
// }
</style>
